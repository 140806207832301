import axios from "axios";

export const AXIOS = axios.create({
  baseURL: `${process.env.VUE_APP_ENDPOINT_API_URI}/public/auth/login`,
  //baseURL: `https://pascal.sferainnovazione.it/api/public/auth/login`,
  //baseURL: `http://localhost:3000/api/public/auth/login`,
  defaults: {
    headers: {
      common: { Accept: "application/json" }
    }
  }
});
