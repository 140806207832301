<template>  
    <v-card elevation="12" color="#7bcfb533">
        <v-navigation-drawer floating permanent v-if="uvax">
            <v-list dense rounded color="#7bcfb533">
                <v-list-item
                    v-for="item in items"
                    :key="item.title"
                    :to="item.to"
                    link
                    :disabled="profile.tipoUser=='bsp'"
                >
                    <v-list-item-icon>
                        <v-icon>{{item.icon}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{item.title}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </v-card>
</template>

<script>
export default {
    data() {
        return {
            // uvax_info: {},
            profile: {
                nome: "",
                cognome: "",
                email: "",
                tipoUser: "",
                resources: []
            },
            // selectedPlant: null,
            items: [
                {title: 'Offerte', icon: 'mdi-view-dashboard', to: '/dashboard'},
                {title: 'Dati Giornalieri', icon: 'mdi-chart-line', to: '/report'},
                {title: 'Dati Storici', icon: 'mdi-chart-line', to: '/report2'},
                {title: 'Indice di Affidabilità', icon: 'mdi-forum', to: '/reliability'}
            ]
        }
    },
    props: {
        user: {},
        uvax: {},
    //   plant: {}
    },
    // watch: {
    //     plant() {
    //         this.initComp();
    //     }
    // },
    methods: {
        // initComp() {
        //     this.selectedPlant = this.$props.plant;
        // }
    },
    mounted () {
        this.profile = this.$props.user;
        // this.uvax_info = this.$props.uvax;
        // this.selectedPlant = this.$props.plant;
    }
}
</script>
