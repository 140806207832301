<script>
  import { Bar, mixins } from 'vue-chartjs'
  const { reactiveProp } = mixins

  export default {
    extends: Bar,
    mixins: [reactiveProp],
    data() {
      return {  
        show_tot: true,
        options: {
          tooltips: {
            mode: 'label',
            callbacks: {
              label: function(tooltipItem, data) {
                var corporation = data.datasets[tooltipItem.datasetIndex].label;
                var valor = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                
                // Loop through all datasets to get the actual total of the index
                var total = 0;
                for (var i = 0; i < data.datasets.length; i++) 
                  total += data.datasets[i].data[tooltipItem.index];               

                // If it is not the last dataset, you display it as you usually do
                if (tooltipItem.datasetIndex != data.datasets.length - 1) {
                    return corporation + " : " + valor;
                } else { // .. else, you display the dataset and the total, using an array
                    return [corporation + " : " + valor, "Total : " + total];
                }                
              }
            }
          },
          scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
            yAxes: [{
              stacked: true,
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: true,
                labelString: "P (kW)"
              }
            }],
            xAxes: [{
              type: "time",
              time: {
                displayFormats: {
                  hour: 'HH:mm',
                }
              },
              stacked: true,
              gridLines: {
                display: false
              },
              scaleLabel: {
                display: true,
                labelString: "Time"
              }
            }]
          },
          legend: {
            display: true
          },
          responsive: true,
          maintainAspectRatio: false
        }
      }
    },
    mounted() {
      this.renderChart(this.chartData, this.options)
    }
  }
</script>