<template>
  <v-app id="app" toolbar>
    <v-main>
      <v-container fluid class="main-container">
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
        <SnackBar />
      </v-container>
    </v-main>
    <v-footer app height="30">
      <FooterBar />
    </v-footer>
  </v-app>
</template>

<script>

//import NavigationBar from "./components/NavigationBar";
import FooterBar from "./components/FooterBar";
import SnackBar from "./components/SnackBar";
//import Drawer from "./components/Drawer";
//import { TokenService } from "@/services/token.service";

export default {
  name: 'App',

  components: {
    //NavigationBar,
    FooterBar,
    SnackBar
    //Navigation,
    //Drawer
  },

  computed: {
    /*
    ...mapState(['drawer']),
    loggedUser() {
      return TokenService.getPayload();
    }*/
  },

  methods: {
      /*
    ...mapMutations({
      setDrawer: 'SET_DRAWER',
    }),*/
  },

  created() {
    //if (!localStorage.locale) localStorage.locale = "it";
    //this.$i18n.locale = "it";//localStorage.locale;
    //this.$store.commit('SET_DRAWER', false)
    // console.log("Logged ",this.logged)
  },

  data: function() {
    return {
    };
  },
};
</script>

<style>
html {
  overflow-y: hidden !important;
}
.v-main {
  height: 0px;
}
.v-main__wrap {
  overflow-y: scroll;
}
.v-subheader.theme--light {
  font-size: 16px !important;
  font-weight: bold !important;
}
</style>
