<template>
  <v-container fluid>
    <NavigationBar :user="profile"/>
    <ProgressCircular v-if="progress" />
    <v-row class="g-2" fluid v-if="!progress && profile.tipoUser != 'bsp'">
      <v-col cols="3">
        <NavigationDrawer :uvax="uvax" :user="profile" />
      </v-col>
      <v-col cols="9">
        <v-card elevation="12" color="#7bcfb533">
          <v-toolbar color="#7bcfb533">
            <!-- v-col class="mt-6" cols="3" v-if="profile.tipoUser != 'plant'">
              <v-select
                  v-model="selectedPlant"
                  :items="plants"
                  item-text="name"
                  item-value="id"
                  label="Seleziona l'impianto"
                  return-object
                  dense
                  solo
                  clearable
                ></v-select>
            </v-col -->
            <v-col cols="4">
                <span>{{uvax.uvax}}</span>
                <span v-if="selectedPlant && profile.tipoUser == 'plant'">/{{selectedPlant.name}}</span>
            </v-col>
          </v-toolbar>
        </v-card>
        <v-card v-if="profile.tipoUser == 'uvax'">
            <v-row class="d-flex justify-center align-center">
                <v-card-title>
                    Offerte Accettate
                </v-card-title>
            </v-row>
            <ProgressCircular v-if="progressOfferteAccettateChart" />
            <div v-if="dataCollectionOfferteAccettate && !progressOfferteAccettateChart">
                <bar-chart :chart-data="dataCollectionOfferteAccettate"></bar-chart>
            </div><br />
        </v-card>
        <v-card v-if="profile.tipoUser == 'uvax'">
            <v-row class="d-flex justify-center align-center">
                <v-card-title>
                    Offerte Selezionate
                </v-card-title>
            </v-row>
            <ProgressCircular v-if="progressOfferteSelezionateChart" />
            <div v-if="dataCollectionOfferteSelezionate && !progressOfferteSelezionateChart">
                <bar-chart :chart-data="dataCollectionOfferteSelezionate"></bar-chart>
            </div><br />
        </v-card>
        <v-card v-if="profile.tipoUser == 'plant'">
            <v-row class="d-flex justify-center align-center">
                <v-card-title>
                    Offerte Selezionate
                </v-card-title>
            </v-row>
            <ProgressCircular v-if="progressOfferteSelezionateChart" />
            <div v-if="dataCollectionOfferteSelezionate && !progressOfferteSelezionateChart">
                <bar-chart :chart-data="dataCollectionOfferteSelezionate"></bar-chart>
            </div><br />
        </v-card>
        <v-card v-if="profile.tipoUser == 'plant'">
            <v-row class="d-flex justify-center align-center">
                <v-card-title>
                    Offerte Attuate
                </v-card-title>
            </v-row>
            <ProgressCircular v-if="progressOfferteAttuateChart" />
            <div v-if="dataCollectionOfferteAttuate && !progressOfferteAttuateChart">
                <bar-chart :chart-data="dataCollectionOfferteAttuate"></bar-chart>
            </div><br />
        </v-card>
      </v-col>
    </v-row>
    <v-row fluid v-else-if="!progress">
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Work in progress for user {{profile.tipoUser}}
          </v-card-title>
        </v-card> 
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { AuthService } from "@/services/auth.service";
import { TokenService } from "@/services/token.service";
import ApiService from "@/services/api.service";
import ProgressCircular from '@/components/ProgressCircular';
import NavigationDrawer from '@/components/NavigationDrawer';
import BarChart from '@/components/BarChart';
import NavigationBar from '@/components/NavigationBar.vue';
import axios from "axios";
import _ from 'lodash';

export default {
    name: 'DashBoard',
    props: {},
    components: {
        ProgressCircular,
        NavigationDrawer,
        BarChart,
        NavigationBar
    },
    data() {
        return {
            progress: true,
            progressOfferteAccettateChart: true,
            progressOfferteSelezionateChart: true,
            progressOfferteAttuateChart: true,
            profile: {},
            uvax: {},
            selectedPlant: null,
            dataCollectionOfferteSelezionate: {
                labels: [],
                datasets: []
            },
            dataCollectionOfferteAttuate: {
                labels: [],
                datasets: []
            },
            dataCollectionOfferteAccettate: {
                labels: [],
                datasets: []
            }
        }
    },
    watch: {
        selectedPlant(newPlant) {
            this.getSelectedPlant(newPlant);
        }
    },
    methods: {
        async logout() {
            await AuthService.logout();
            this.$router.push({name: "LoginPage"}).catch(() => {});
        },
        getSelectedPlant(plant) {
            if (plant) {
                this.selectedPlant = plant;
            } else {
                if (this.plants && this.plants.length > 0) {
                    this.selectedPlant = this.plants[0];
                }
            }
        },
        async getPlants() {
            if (TokenService.isTokenValid()) {
                const requestData = {
                    method: 'get',
                    headers: {
                        Authorization: `Bearer ${TokenService.getToken()}`,
                    },
                    url: `${process.env.VUE_APP_ENDPOINT_API_URI}/private/report/plant`,
                };
                const response = await ApiService.customRequest(requestData);
                if (response.data) {
                    this.uvax = response.data;
                    this.plants = response.data.plants;
                }
            } else {
                this.logout();
            }
        },
        async getOfferteAccettate() {
            if (TokenService.isTokenValid()) {
                this.progressOfferteAccettateChart = true;
                let xAxes = [];
                let datasets = [];
                axios.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getToken()}`;
                axios.get(`${process.env.VUE_APP_ENDPOINT_API_URI}/private/report/offerte_accettate`).then(res => {
                    try{
                        console.log("OFFERTE ACCETTATE RES ", res.data)
                        if (res.data.length > 0) {
                            xAxes = res.data[0].xAxes;
                            for (let i = 0; i < res.data.length; i++) {
                                let resourceData = res.data[i];
                                if (resourceData.yAxes.length > 0) {
                                    //let randomColour = `#${((1 << 24) * Math.random() | 0).toString(16)}`;
                                    let color = "#000000"
                                    let dataset = {
                                        label: `${resourceData.plot_type}`,
                                        borderWidth: 1,
                                        borderColor: color,
                                        backgroundColor: color,
                                        pointBorderColor: color,
                                        data: resourceData.yAxes,
                                        stack: 'Stack 0'
                                    }
                                    datasets.push(dataset);
                                }
                            }
                        }
                        
                        this.dataCollectionOfferteAccettate = {
                            labels: xAxes,
                            datasets: datasets
                        }
                        this.progressOfferteAccettateChart = false;

                    } catch(e) {
                        console.log(e);
                        this.progressOfferteAccettateChart = false;
                    }
                });
            } else {
                this.logout();
            }
        },
        async getOfferteSelezionate() {
            if (TokenService.isTokenValid()) {
                this.progressOfferteSelezionateChart = true;
                let xAxes = [];
                let datasets = [];
                axios.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getToken()}`;
                axios.get(`${process.env.VUE_APP_ENDPOINT_API_URI}/private/report/offerte_selezionate`).then(res => {
                    try{
                        console.log("OFFERTE SELEZIONATE RES ", res.data)
                        if (res.data.length > 0) {
                            let dataRes = res.data;
                            if (this.loggedUser.account.tipoUser == "plant") {
                                let plant_index = this.plants.indexOf(this.selectedPlant);
                                let plot_value = `R${plant_index+1}`
                                if (this.uvax.uvax == "UVAM_FERIALE_ESTIVO_COLGP" && plant_index == 4) {
                                    plot_value = `R${plant_index}`
                                }
                                dataRes = _.filter(dataRes, ['plot_type', plot_value]);
                            }
                            if (dataRes.length > 0) {
                                xAxes = dataRes[0].xAxes;
                                for (let i = 0; i < dataRes.length; i++) {
                                    let resourceData = dataRes[i];
                                    if (resourceData.yAxes.length > 0) {
                                        let randomColour = `#${((1 << 24) * Math.random() | 0).toString(16)}`;
                                        let color = randomColour;
                                        if (this.loggedUser.account.tipoUser == "plant") {
                                            color = (this.selectedPlant && this.selectedPlant.color) ? this.selectedPlant.color : color
                                        } else {
                                            let index = resourceData.plot_type.substr(resourceData.plot_type.length - 1);
                                            color = (this.plants[index-1] && this.plants[index-1].color) ? this.plants[index-1].color : color
                                            // Nel caso di UVAM_FERIALE_ESTIVO_COLGP R4 coincide con il quinto impianto
                                            if (this.uvax.uvax == "UVAM_FERIALE_ESTIVO_COLGP" && index == 4) {
                                                color = (this.plants[index] && this.plants[index].color) ? this.plants[index].color : color
                                            }
                                        }
                                        let label = resourceData.plot_type;
                                        if (this.plants[i] && this.plants[i].name) {
                                            label = this.plants[i].name;
                                        }                                        
                                        let dataset = {
                                            label: `${label}`,
                                            borderWidth: 1,
                                            borderColor: color,
                                            backgroundColor: color,
                                            pointBorderColor: color,
                                            data: resourceData.yAxes,
                                            stack: 'Stack 0'
                                        }
                                        datasets.push(dataset);
                                    }
                                }
                            }
                        }
                        
                        this.dataCollectionOfferteSelezionate = {
                            labels: xAxes,
                            datasets: datasets
                        }
                        this.progressOfferteSelezionateChart = false;
                    } catch(e) {
                        console.log(e);
                        this.progressOfferteSelezionateChart = false
                    }
                });
            } else {
                this.logout();
            }
        },
        async getOfferteAttuate() {
            if (TokenService.isTokenValid()) {
                this.progressOfferteAttuateChart = true;
                let xAxes = [];
                let datasets = [];
                axios.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getToken()}`;
                axios.get(`${process.env.VUE_APP_ENDPOINT_API_URI}/private/report/offerte_attuate`).then(res => {
                    try{
                        console.log("OFFERTE ATTUATE RES ", res.data)
                        if (res.data.length > 0) {
                            let dataRes = res.data;
                            if (this.loggedUser.account.tipoUser == "plant") {
                                let plant_index = this.plants.indexOf(this.selectedPlant);
                                let plot_value = `R${plant_index+1}`
                                if (this.uvax.uvax == "UVAM_FERIALE_ESTIVO_COLGP" && plant_index == 4) {
                                    plot_value = `R${plant_index}`
                                }
                                dataRes = _.filter(dataRes, ['plot_type', plot_value]);
                            }
                            if (dataRes.length > 0) {
                                xAxes = dataRes[0].xAxes;
                                for (let i = 0; i < dataRes.length; i++) {
                                    let resourceData = dataRes[i];
                                    if (resourceData.yAxes.length > 0) {
                                        let randomColour = `#${((1 << 24) * Math.random() | 0).toString(16)}`;
                                        let color = randomColour;
                                        if (this.loggedUser.account.tipoUser == "plant") {
                                            color = (this.selectedPlant && this.selectedPlant.color) ? this.selectedPlant.color : color
                                        } else {
                                            let index = resourceData.plot_type.substr(resourceData.plot_type.length - 1);
                                            color = (this.plants[index-1] && this.plants[index-1].color) ? this.plants[index-1].color : color
                                            // Nel caso di UVAM_FERIALE_ESTIVO_COLGP R4 coincide con il quinto impianto
                                            if (this.uvax.uvax == "UVAM_FERIALE_ESTIVO_COLGP" && index == 4) {
                                                color = (this.plants[index] && this.plants[index].color) ? this.plants[index].color : color
                                            }
                                        }
                                        let label = resourceData.plot_type;
                                        if (this.plants[i] && this.plants[i].name) {
                                            label = this.plants[i].name;
                                        }
                                        let dataset = {
                                            label: `${label}`,
                                            borderWidth: 1,
                                            borderColor: color,
                                            backgroundColor: color,
                                            pointBorderColor: color,
                                            data: resourceData.yAxes,
                                            stack: 'Stack 0'
                                        }
                                        datasets.push(dataset);
                                    }
                                }
                            }
                        }
                        
                        this.dataCollectionOfferteAttuate = {
                            labels: xAxes,
                            datasets: datasets
                        }
                        this.progressOfferteAttuateChart = false;
                    } catch(e) {
                        console.log(e);
                        this.progressOfferteAttuateChart = false;
                    }
                });
            } else {
                this.logout();
            }
        }
    },
    computed: {
        loggedUser() {
            return TokenService.getPayload();
        },
    },
    async mounted () {
        this.profile = this.loggedUser.account;
        if (this.profile.tipoUser != 'bsp') {
            await this.getPlants();
            await this.getSelectedPlant();
            await this.getOfferteSelezionate();
            if (this.profile.tipoUser == 'uvax') {
                await this.getOfferteAccettate();
            }
            if (this.profile.tipoUser == 'plant') {
                await this.getOfferteAttuate();
            }
        }
        this.progress = false;
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .container{
        margin-left: 0px;
        margin-right: 0px;
    }

    .row {
        margin-top: 10px;
    }

    h3 {
        margin: 40px 0 0;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    a {
        color: #42b983;
    }
</style>
