<template>
    <v-container fluid>
        <NavigationBar :user="profile" />
        <ProgressCircular v-if="progress" />
        <v-row fluid v-else>
            <v-col cols="3">
                <NavigationDrawer :uvax="uvax" :user="profile" />
            </v-col>
            <v-col cols="9" class="col-sm-9 px-0">
                <v-card elevation="12" color="#7bcfb533">
                    <v-toolbar color="#7bcfb533">
                        <v-col class="mt-6" cols="3" v-if="profile.tipoUser != 'plant'">
                            <v-select
                                v-model="selectedPlant"
                                :items="plants"
                                item-text="name"
                                item-value="id"
                                label="Seleziona l'impianto"
                                return-object
                                dense
                                solo
                                clearable
                            ></v-select>
                        </v-col>
                        <v-col cols="6">
                            <span>{{uvax.uvax}}</span>
                            <span v-if="selectedPlant">/{{selectedPlant.name}}</span>
                        </v-col>
                    </v-toolbar>
                </v-card>
                <v-card>
                    <v-row class="d-flex justify-center align-center">
                        <v-col cols="1" class="text-right" style="margin-top: -22px;">
                            <label>Dati</label>
                        </v-col>
                        <v-col cols="4">
                            <v-select
                                v-model="selectedPlotTypes"
                                :items="plotTypes"
                                item-text="text"
                                item-value="value"
                                label="Seleziona i dati da rappresentare"
                                dense
                                solo
                                clearable
                                :multiple="true"
                                :disabled="!selectedPlant"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex justify-center align-center">
                        <v-col class="text-left" cols="3">
                            <label>Data 1</label>
                            <datetime format="YYYY-MM-DD" v-model="date1"></datetime>
                        </v-col>
                        <v-col class="text-left" cols="3">
                            <label>Data 2</label>
                            <datetime format="YYYY-MM-DD" v-model="date2"></datetime>
                        </v-col>
                    </v-row>
                    <br />
                    <v-row class="d-flex justify-center align-center">
                        <v-col class="text-center">
                            <v-btn
                                :disabled="!selectedPlant || selectedPlotTypes.length == 0 || (!date1 && !date2)"
                                rounded
                                center
                                color="#7bcfb2"
                                @click="getData()"
                            >
                            Carica dati
                            </v-btn>
                        </v-col>
                    </v-row>
                    <br />
                    <div v-if="progressChart">
                        <ProgressCircular />
                        <br />
                    </div>
                    <div v-if="selectedPlant && selectedPlotTypes.length > 0 && (date1 || date2) && dataCollection">
                        <line-chart-v-2 :chart-data="dataCollection" id="line" :key="componentKey"></line-chart-v-2>
                        <br />
                        <v-row class="d-flex justify-center align-center">
                            <v-col cols="2" class="text-center">
                                <v-btn
                                    rounded
                                    center
                                    outlined
                                    color="#7bcfb5"
                                    @click="saveImage()"
                                >
                                Salva immagine
                                </v-btn>
                            </v-col>
                            <v-col cols="2" class="text-center">
                                <!--vue-blob-json-csv
                                    rounded
                                    center
                                    outlined
                                    tag-name="div"
                                    file-type="csv"
                                    title="Download csv"
                                    file-name="report"
                                    :data="csvExportData"
                                    confirm="Sei sicuro di voler scaricare un file csv?"
                                    /-->
                                <v-btn
                                    rounded
                                    center
                                    outlined
                                    color="#7bcfb5"
                                    dark
                                >
                                    <vue-blob-json-csv
                                        tag-name="div"
                                        file-type="csv"
                                        title="Download csv"
                                        file-name="report"
                                        :data="csvExportData"
                                        confirm="Sei sicuro di voler scaricare un file CSV?"
                                    />
                                </v-btn>
                            </v-col>
                            <v-col cols="2" class="text-center">
                                <v-btn
                                    rounded
                                    center
                                    outlined
                                    color="#7bcfb5"
                                    @click="downloadPDF()"
                                >
                                Download PDF
                                </v-btn>
                            </v-col>
                            <v-col cols="2" class="text-center">
                                <v-btn
                                    rounded
                                    center
                                    outlined
                                    color="#7bcfb5"
                                    @click="resetZoom()"
                                >
                                Reset zoom
                                </v-btn>
                            </v-col>
                        </v-row>
                        <br />
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import datetime from 'vuejs-datetimepicker';
import { AuthService } from "@/services/auth.service";
import { TokenService } from "@/services/token.service";
import ApiService from "@/services/api.service";
import ProgressCircular from '@/components/ProgressCircular';
import NavigationDrawer from '@/components/NavigationDrawer';
import LineChartV2 from '@/components/LineChartV2';
import NavigationBar from '@/components/NavigationBar.vue';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';

export default {
    name: 'ReportPage',
    props: {},
    components: {
        ProgressCircular,
        NavigationDrawer,
        LineChartV2,
        datetime,
        NavigationBar
    },
    data() {
        return {
            componentKey: 0,
            progress: true,
            progressChart: false,
            profile: {},
            uvax: {},
            plants: [],
            selectedPlant: null,
            dataCollection: null,
            selectedPlotTypes: [],
            plotTypes: [
                {text: "Baseline", value: "baseline", disabled: false},
                {text: "Flessibilità", value: "flexibility", disabled: false}
            ],
            date1: moment().subtract(1, "d").startOf("day").format("YYYY-MM-DD"),
            date2: "",
            csvExportData: []
        }
    },
    watch: {
        selectedPlant() {
            this.dataCollection = null;

            if (this.selectedPlant) {
                this.plotTypes = [
                    {text: `Baseline (${this.selectedPlant.name})`, value: 'baseline', disabled: false},
                    {text: `Flessibilità (${this.selectedPlant.name})`, value: 'flexibility', disabled: false}
                ];
            }
        }
    },
    methods: {
        async logout() {
            await AuthService.logout();
            this.$router.push({name: "LoginPage"}).catch(() => {});
        },
        async getData() {
            if (TokenService.isTokenValid()) {
                this.dataCollection = null;
                this.csvExportData = [];

                let xAxes = [];
                let datasets = [];

                let plots = [];
                for (let i = 0; i < this.selectedPlotTypes.length; i++) {
                    plots.push({plot_type: this.selectedPlotTypes[i], plant_id: this.selectedPlant.id});
                }

                let dates = [];
                if (this.date1) dates.push(this.date1);
                if (this.date2) dates.push(this.date2);

                if (dates.length == 0) return;

                this.progressChart = true;

                for (let k = 0; k < dates.length; k++) {
                    const params = {
                        plots: plots,
                        from_date: dates[k],
                        to_date: dates[k]
                    };

                    axios.defaults.headers.common['Authorization'] = `Bearer ${TokenService.getToken()}`;
                    await axios.get(`${process.env.VUE_APP_ENDPOINT_API_URI}/private/report/baseline_flexibility`, {params: params}).then(res => {
                        if (res.data.length > 0) {
                            for (let i = 0; i < res.data.length; i++) {
                                let resourceData = res.data[i];
                                xAxes = resourceData.xAxes;

                                // Costruisco il dataset per rappresentare i grafici
                                if (resourceData.yAxes.length > 0) {
                                    //let randomColour = `#${((1 << 24) * Math.random() | 0).toString(16)}`;
                                    let color = this.selectedPlant.color;
                                    if (k==1) {
                                        color = this.selectedPlant.opacity_color;
                                    }
                                    let dataset = {
                                        label: `${resourceData.plot_type} ${dates[k]}`,
                                        data: resourceData.yAxes,
                                        borderColor: color, //randomColour,
                                        backgroundColor: color, //randomColour,
                                        fill: false
                                    }
                                    if (resourceData.plot_type != "baseline") {
                                        dataset.borderDash = [10, 10];
                                        dataset.pointStyle = "triangle";
                                        dataset.borderWidth = 3;
                                    }
                                    if (resourceData.plot_type == "max_power") {
                                        dataset.borderDash = [20, 20];
                                    }
                                    datasets.push(dataset);
                                }

                                // Costruisco l'array di oggetti per l'export del file CSV
                                for (let j = 0; j < resourceData.xAxes.length; j++) {
                                    let rowObj = {
                                        plot_type: resourceData.plot_type,
                                        plant: this.selectedPlant.id,
                                        time: moment(resourceData.xAxes[j]).format("HH:mm:ss")
                                    };
                                    if (resourceData.yAxes[j]) {
                                        rowObj.value = resourceData.yAxes[j];
                                    }
                                    this.csvExportData.push(rowObj);
                                }
                            }
                        }
                    });
                }

                this.dataCollection = {
                    labels: xAxes,
                    datasets: datasets
                }

                this.progressChart = false;
            } else {
                this.logout();
            }
        },
        async getPlants() {
            if (TokenService.isTokenValid()) {
                const requestData = {
                    method: 'get',
                    headers: {
                        Authorization: `Bearer ${TokenService.getToken()}`,
                    },
                    url: `${process.env.VUE_APP_ENDPOINT_API_URI}/private/report/plant`,
                };
                const res = await ApiService.customRequest(requestData);
                if (res.data) {
                    this.uvax = res.data;
                    this.plants = res.data.plants;

                    if (this.profile.tipoUser == 'plant') {
                        this.selectedPlant = this.plants[0];
                    }
                }
            } else {
                this.logout();
            }
        },
        saveImage() {
            let canvas = document.getElementById('line-chart').toDataURL('image/png');
            let link = document.createElement('a');
            link.download = 'chart';
            link.href = canvas;
            link.click();
        },
        async downloadPDF() {
            const doc = new jsPDF();
            const fontName = 'Helvetica';
            const marginX = 15;
            const marginY = 20;

            // Title:
            let startX = marginX;
            let startY = marginY;
            doc.setFont(fontName, 'bold');
            doc.setFontSize(20);
            doc.text('Dati giornalieri', startX, startY);

            // Filters:
            startY += 5;
            doc.setFontSize(10);
            const headers = [
                [{text: 'Risorsa:', bold: true}, {text: this.selectedPlant.name}],
                [{text: 'Tipo dati:', bold: true}, {text: this.selectedPlotTypes.join(', ')}],
                [{text: 'Data 1:', bold: true}, {text: this.date1 ? this.date1 : '-'}],
                [{text: 'Data 2:', bold: true}, {text: this.date2 ? this.date2 : '-'}]
            ];
            headers.forEach(parts => {
                startX = marginX;
                startY += 5;
                parts.forEach(part => {
                    doc.setFont(fontName, part.bold ? 'bold' : 'normal');
                    const text = part.text ?? '';
                    doc.text(text, startX, startY);
                    startX += 25;
                });
            });

            // Chart:
            startX = marginX;
            startY += 10;
            const chart = document.getElementById('line');
            const canvas = await html2canvas(chart, {
                scrollY: -window.scrollY,
                scale: 5 // <-- this is to increase the quality. don't raise this past 5 as it doesn't get much better and just takes longer to process
            });
            const image = canvas.toDataURL('image/jpeg', 1.0);
            const pageWidth = doc.internal.pageSize.getWidth();
            const ratio = (pageWidth - (marginX * 2)) / canvas.width;
            const canvasWidth = canvas.width * ratio;
            const canvasHeight = canvas.height * ratio;
            doc.addImage(image, 'JPEG', startX, startY, canvasWidth, canvasHeight);

            // Table:
            doc.autoTable({
                startY: startY + canvasHeight + 10,
                head: [Object.keys(this.csvExportData[0])],
                body: this.csvExportData.map(Object.values)
            });

            // Page number:
            const pageHeight = doc.internal.pageSize.getHeight();
            for (let i = 1; i <= doc.internal.getNumberOfPages(); i++) {
                doc.setPage(i);
                doc.text(`${i}`, pageWidth / 2, pageHeight - 10);
            }

            // Saving:
            let fileName = `dailies_${this.selectedPlant.name}`;
            fileName += this.selectedPlotTypes.length > 1 ? '_all' : `_${this.selectedPlotTypes}`;
            fileName += this.date1 ? `_${this.date1}` : '';
            fileName += this.date2 ? `_${this.date2}` : '';
            doc.save(fileName);
        },
        resetZoom() {
            this.componentKey += 1;
            //this.getData();
        },
    },
    computed: {
        loggedUser() {
            return TokenService.getPayload();
        },
    },
    async mounted() {
        this.profile = this.loggedUser.account;
        await this.getPlants();
        this.progress = false;
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container{
    margin-left: 0px;
    margin-right: 0px;
}

.row {
    margin-top: 10px;
}

h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}
</style>
