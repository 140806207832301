<template>
  <div>
    <NavigationBar :user="user"></NavigationBar>
    <v-container style="border: 5px solid #7bcfb5;margin-top: 6%">

      <v-row align="center" justify="center">
        <v-col cols="6" class="text-center px-3">
          <img :src="require('../assets/pascal_logo.png')" width="320" />
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="3" class="text-center px-3">
          <img :src="require('../assets/algowatt_logo.png')" width="150" />
        </v-col>
        <v-col cols="2" class="text-center px-3">
          <img :src="require('../assets/col_logo.png')" width="70" />
        </v-col>
        <v-col cols="2" class="text-center px-3">
          <img :src="require('../assets/cometa_logo.png')" width="50" />
        </v-col>
        <v-col cols="2" class="text-center px-3">
          <img :src="require('../assets/sf_logo.png')" width="30" />
        </v-col>
      </v-row>
      <v-row align="center" justify="center" class="my-12">
        <v-col cols="6">
          <v-form>
            <div class="form-group">
              <v-text-field
                class="login-email"
                label="Nome utente"
                v-model="username"
                required
              ></v-text-field>
            </div>
            <div class="form-group">
              <v-text-field
                class="login-password"
                label="Password"
                :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                @click:append="showPassword = !showPassword"
                :type="showPassword ? 'password' : 'text'"
                v-model="password"
                required
                el-input
                @keyup.enter.native="submit"
              ></v-text-field>
            </div>
          </v-form>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" style="margin-bottom:0px">
        <v-btn large @click="submit" color="primary">
          <font color="white">Log in</font>
        </v-btn>
        <!--v-btn text :disabled="!username" @click="forgot_password">
          <font color="secondary">{{$t('buttons.forgot_password')}}</font>
        </v-btn-->
      </v-row>
    </v-container>
  </div>
</template>

<script>
//import { mapActions, mapGetters } from "vuex";
import { AuthService } from "@/services/auth.service";
import NavigationBar from "@/components/NavigationBar.vue";
//import Enums from "@/mixins/enums";

const computed = {
}

export default {
  components:{
    NavigationBar
  },
  data() {
    return {
      username: "",
      password: "",
      progress: true,
      showPassword: true,
      user: {email: null}
    };
  },
  methods: {
    //...mapActions("status", ["logged"]),
    toast(context, mode, text) {
      const messageObj = {
        context,
        mode,
        text,
        snackbar: true
      };
      this.$store.dispatch("snackbar/showMessage", messageObj);
    },
    async submit() {
      const res=await AuthService.login(this.username, this.password);
      if (res.error) {
        this.toast("red", "", "Username or password incorrect");
      }
      else {
        this.$router.push({ path: '/dashboard' });
      }
    }
  },
  computed
};
</script>

<style scoped>
.container {
  width: 50%;
}
</style>
