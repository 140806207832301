<template color="primary">
  <v-row align="center" justify="space-between">
    <v-col cols="4" align="left" class="py-0 mt-n1">
      <span class="caption">{{ version }}</span>
    </v-col>
    <v-col cols="4" align="center" class="py-0 mt-n1">
        <span class="caption" style="color: green;"> Sfera s.r.l.</span>
        <!--span class="caption">POWERED BY <img :src="require('../assets/sf_logo.png')" width="30" /></span--> 
    </v-col>
    <v-col cols="4" align="right" class="py-0 mt-n1">
      <span class="caption"> {{ now }}</span>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'

//import { mapGetters } from "vuex";
//import SharedMixin from "@/mixins/shared";

export default {
  data() {
    return {
      currentTime: ""
    };
  },
  //mixins: [SharedMixin],
  computed: {
    //...mapGetters("login", ["isLogged", "applicationVersion"]),
    //...mapGetters("common", ["now"]),
    version() {
        return "Versione 1.0"
        /*
      var v = 0;
      if (this.applicationVersion) {
        v = this.applicationVersion.split("-")[0] || "-";
      }
      return this.$t("app_version", {
        version: v
      });*/
    },
    now() {
      return moment().format("YYYY-MM-DD")
    }
  },
  created() {}
};
</script>