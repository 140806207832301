import Vue from 'vue'
import VueRouter from 'vue-router'
import DashBoard from '../views/DashBoard.vue'
import LoginPage from '../views/LoginPage.vue'
import HomePage from '../views/HomePage.vue'
import ReportPage from '../views/ReportPage.vue'
import ReportPageV2 from '../views/ReportPageV2.vue'
import ReliabilityPage from '../views/ReliabilityPage.vue'
import UserProfile from '../components/User/UserProfile.vue'

import store from "@/store";
import { TokenService } from "@/services/token.service";

Vue.use(VueRouter)

const routes = [  
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage
  },
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/dashboard',
    name: 'DashBoard',
    component: DashBoard
  },
  {
    path: '/report',
    name: 'ReportPage',
    component: ReportPage
  },
  {
    path: '/report2',
    name: 'ReportPage2',
    component: ReportPageV2
  },
  {
    path: '/reliability',
    name: 'ReliabilityPage',
    component: ReliabilityPage
  },
  {
    path: '/profile',
    name: 'UserProfile',
    component: UserProfile
  }
]

const router = new VueRouter({
    //mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {

  const loggedIn = localStorage.getItem("jwt");
  //if (loggedIn) ApiService.setHeader();
  if (loggedIn) store.commit("status/setLogged", true);
  const validToken = TokenService.isTokenValid();
  if (!validToken) { 
    TokenService.removeToken();
    store.commit("status/setLogged", false);
  }

  const publicPages = ["LoginPage"];
  const authRequired = !publicPages.includes(to.name);

  console.log("validToken ", validToken)
  
  if (!authRequired) {
    next();
  } else if (!loggedIn || !validToken) {
    return next({ name: "LoginPage" });
  } else {
    next();
  }

})

export default router