<template>  
  <v-card elevation="12" color="#7bcfb533">
    <v-card-title>
        <v-col cols="2">
            <v-btn icon>
                <v-icon color="black" @click="goBack()">mdi-arrow-left-thin-circle-outline</v-icon>
            </v-btn>
        </v-col>
        <v-col cols="10" class="text-left">Profilo utente</v-col>    
    </v-card-title>
    <v-card-text class="text-center">
        <v-row>
            <v-col cols="3" class="font-weight-bold text-right">Nome:</v-col>
            <v-col cols="3" class="text-left">{{ profile.nome }}</v-col>
        </v-row>
        <v-row>
            <v-col cols="3" class="font-weight-bold text-right">Cognome:</v-col>
            <v-col cols="3" class="text-left">{{ profile.cognome }}</v-col>
        </v-row>
        <v-row>
            <v-col cols="3" class="font-weight-bold text-right">Email:</v-col>
            <v-col cols="3" class="text-left">{{ profile.email }}</v-col>
        </v-row>
        <v-row>
            <v-col cols="3" class="font-weight-bold text-right">Tipo:</v-col>
            <v-col cols="3" class="text-left">{{ profile.tipoUser }}</v-col>
        </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

import { TokenService } from "@/services/token.service";

  export default {
    data () {
      return {
        profile: {
          nome: "",
          cognome: "",
          email: "",
          tipoUser: "",
          resources: []
        }
      }
    },
    props: {
      user: {}
    },
    computed: {
        loggedUser() {
            return TokenService.getPayload();
        }
    },
    methods: {
        goBack(){
            this.$router.push({ name: "DashBoard" }).catch(()=>{});
        }
    },
    mounted () {
        this.profile = this.loggedUser.account;
    }
  }
</script>