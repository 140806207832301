<template>
  <v-container fluid>
    <ProgressCircular v-if="progress" />
    <v-row fluid v-if="!progress">
      <v-col cols="3">
        <NavigationDrawer :plant="plant" />        
      </v-col>      
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'

import ProgressCircular from '@/components/ProgressCircular'
import NavigationDrawer from '@/components/NavigationDrawer'

export default {
  name: 'HomePage',
  props: {
  },
  components: {
    ProgressCircular,
    NavigationDrawer
  },
  data() {
    return {
      progress: true,
      plant: null
    }
  },
  methods: {
    getPlantDetails(){
      const params = {
        params: null,
        data: null
      }
      axios.get(`${process.env.VUE_APP_ENDPOINT_API_URI}/private/report/plant`, { params: params }).then(resp => {
        if (resp.data.length > 0) {
          this.plant = resp.data[0];
          this.progress = false;
        }        
      });
    }
  },
  mounted () {
    this.getPlantDetails();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container{
margin-left: 0px;
margin-right: 0px;
}

.row {
  margin-top: 10px;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>