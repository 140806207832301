import { TokenService } from "./token.service";
import ApiService from "./api.service";
import store from "@/store";

async function login(username, password) {
  
  const requestData = {
    method: "post",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
    data: {
      username: username,
      password: password
    }
  };
  try {
    const response = await ApiService.customRequest(requestData);
    if (response && response.data) {
      TokenService.saveToken(response.data);
      store.commit("status/setLogged", true);
      return response.data;
    }
  } catch (error) {
      console.log("CATCH error ", error)
    return {error};
  }
}

function logout() {
  TokenService.removeToken();
  //ApiService.removeHeader();
  store.commit("status/setLogged", false);
}

export const AuthService = {
  login,
  logout
};
