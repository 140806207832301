<template>
  <div>
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      color="primary"
      app
      height="73"
    >
      <div class="bar-element">
        <v-app-bar-nav-icon color="white" style="pointer-events: none;"><v-icon>mdi-alpha-h-circle-outline</v-icon></v-app-bar-nav-icon>
        <v-toolbar-title style="color: black;">Piattaforma reportistica PASCAL</v-toolbar-title>
      </div>
      <div class="bar-element" v-if="logged">
        <!--Navigation></Navigation-->
      </div>
      <div class="bar-right-group">
        <div class="bar-element" v-if="logged && user.email">
          <!--UserProfile></UserProfile-->
          <!--
            <v-btn icon>
              <v-icon color="white" @click="goTo()">mdi-account-circle-outline</v-icon>
            </v-btn>
          -->
           Utente:&nbsp;<strong>{{ user.email }}</strong>&nbsp;&nbsp;&nbsp;Livello:&nbsp;<strong>{{ level }}</strong>
        </div>
        <div class="bar-element" v-if="logged">
          <v-btn icon>
            <v-icon color="white" @click="logout()">mdi-logout</v-icon>
          </v-btn>
        </div>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { AuthService } from "@/services/auth.service";
//da rimuovere se si vuole tornare alla vecchia config
import { TokenService } from "@/services/token.service";

export default {
  name: "NavigationBar",
  components: {
  },
  data() {
    return {
      //da rimuovere se si vuole tornare alla vecchia config
      profile: {
          nome: "",
          cognome: "",
          email: "",
          tipoUser: "",
          resources: []
        },
      level: null
    };
  },
  props:{
    user: {}
  }
  ,
  computed: {
    ...mapGetters("status", ["logged"]),
    //da rimuovere se si vuole tornare alla vecchia config
    loggedUser() {
        return TokenService.getPayload();
    }
  },
  methods: {
    goTo() {
      this.$router.push({ name: "UserProfile" }).catch(()=>{});
    },
    async logout(){
      await AuthService.logout();
      this.$router.push({ name: "LoginPage" }).catch(()=>{});
    }
  },
  //da rimuovere se si vuole tornare alla vecchia config
  mounted(){
    this.profile = this.loggedUser.account;
    try{
      if(this.profile && this.profile.tipoUser == "uvax"){
        this.level = "UVAX"
      }else{
        this.level = "Impianto"
      }
    }catch(e){
      console.log(e)
    }
    
  }
};
</script>

<style scoped>
.bar-element {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  margin-left: 10px;
  margin-right: 10px;
  height: 65px;
}
.bar-right-group {
  position: absolute;
  right: 16px;
  height: 70px;
}
ul {
  padding-left: 0px !important;
}
</style>
