<template>
    <v-container fluid>
        <NavigationBar :user="profile" />
        <ProgressCircular v-if="progress" />
        <v-row fluid v-else>
            <v-col cols="3">
                <NavigationDrawer :uvax="uvax" :user="profile" />
            </v-col>
            <v-col cols="9" class="col-sm-9 px-0">
                <v-card elevation="12" color="#7bcfb533">
                    <v-toolbar color="#7bcfb533">
                        <v-col class="mt-6" cols="3" v-if="this.profile.tipoUser != 'plant'">
                            <v-select
                                v-model="selectedPlants"
                                :items="plants"
                                item-text="name"
                                item-value="id"
                                label="Seleziona l'impianto"
                                return-object
                                dense
                                solo
                                clearable
                                :multiple="true"
                            ></v-select>
                        </v-col>
                        <v-col cols="6">
                            <span>{{uvax.uvax}}</span>
                            <span v-if="selectedPlantsLabel">/{{selectedPlantsLabel}}</span>
                        </v-col>
                    </v-toolbar>
                </v-card>
                <v-card>
                    <v-row class="d-flex justify-center align-center" v-if="profile.tipoUser != 'plant'">
                        <v-col cols="2" class="text-right" style="margin-top: -22px;">
                            <label>Tipo di grafico</label>
                        </v-col>
                        <v-col cols="4">
                            <v-select
                                v-model="selectedPlotType"
                                :items="plotTypes"
                                item-text="text"
                                item-value="value"
                                dense
                                solo
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex justify-center align-center">
                        <v-col class="text-left" cols="3">
                            <label>Data iniziale</label>
                            <datetime format="YYYY-MM-DD" v-model="startDate"></datetime>
                        </v-col>
                        <v-col class="text-left" cols="3">
                            <label>Data finale</label>
                            <datetime format="YYYY-MM-DD" v-model="endDate"></datetime>
                        </v-col>
                        <v-col class="text-left" cols="4" style="padding-top: 40px;">
                            <label>Campionamento</label>
                            <v-select
                                v-model="selectedSamplingRate"
                                :items="samplingRates"
                                item-text="text"
                                item-value="value"
                                label="Seleziona la frequenza di campionamento"
                                dense
                                solo
                            ></v-select>
                        </v-col>
                    </v-row>
                    <br />
                    <v-row class="d-flex justify-center align-center">
                        <v-col class="text-center">
                            <v-btn
                                :disabled="selectedPlants.length == 0 || (!startDate && !endDate)"
                                rounded
                                center
                                color="#7bcfb5"
                                @click="getData()"
                            >
                            Carica dati
                            </v-btn>
                        </v-col>
                    </v-row>
                    <br />
                    <div v-if="progressChart">
                        <ProgressCircular />
                        <br />
                    </div>
                    <div v-if="dataCollection">                    
                        <div class="text-center">
                            Sampling Power Data
                        </div>
                        <div v-if="selectedPlotType == 'line'">
                            <line-chart :chart-data="dataCollection" class="chart" id="line" :key="componentKey"></line-chart>
                        </div>
                        <div v-if="selectedPlotType == 'bar'">
                            <bar-chart :chart-data="dataCollection" class="chart" id="bar"></bar-chart>
                        </div>
                        <div v-if="selectedPlotType == 'bar2'">
                            <bar-chart-v-2 :chart-data="dataCollection" class="chart" id="bar2"></bar-chart-v-2>
                        </div>
                        <br />
                        <v-row class="d-flex justify-center align-center">
                            <v-col cols="2" class="text-center">
                                <v-btn
                                    rounded
                                    center
                                    outlined
                                    color="#7bcfb5"
                                    @click="saveImage()"
                                >
                                Salva immagine
                                </v-btn>
                            </v-col>
                            <v-col cols="2" class="text-center">
                                <!--vue-blob-json-csv
                                    rounded
                                    center
                                    outlined
                                    tag-name="div"
                                    file-type="csv"
                                    title="Download csv"
                                    file-name="report"
                                    :data="csvExportData"
                                    confirm="Sei sicuro di voler scaricare un file csv?"
                                /-->
                                <v-btn
                                    rounded
                                    center
                                    outlined
                                    color="#7bcfb5"
                                    dark
                                >
                                    <vue-blob-json-csv
                                        tag-name="div"
                                        file-type="csv"
                                        title="Download csv"
                                        file-name="report"
                                        :data="csvExportData"
                                        confirm="Sei sicuro di voler scaricare un file CSV?"
                                    />
                                </v-btn>
                            </v-col>
                            <v-col cols="2" class="text-center">
                                <v-btn
                                    rounded
                                    center
                                    outlined
                                    color="#7bcfb5"
                                    @click="downloadPDF()"
                                >
                                Download PDF
                                </v-btn>
                            </v-col>
                            <v-col cols="2" class="text-center" v-if="selectedPlotType == 'line'">
                                <v-btn
                                    rounded
                                    center
                                    outlined
                                    color="#7bcfb5"
                                    @click="resetZoom()"
                                >
                                Reset zoom
                                </v-btn>
                            </v-col>
                        </v-row>
                        <br />
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import datetime from 'vuejs-datetimepicker';
import { AuthService } from "@/services/auth.service";
import { TokenService } from "@/services/token.service";
import ApiService from "@/services/api.service";
import ProgressCircular from '@/components/ProgressCircular';
import NavigationDrawer from '@/components/NavigationDrawer';
import LineChart from '@/components/LineChart';
import BarChart from '@/components/BarChart';
import BarChartV2 from '@/components/BarChartV2';
import NavigationBar from '@/components/NavigationBar.vue';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';

export default {
    name: 'ReportPageV2',
    props: {},
    components: {
        ProgressCircular,
        NavigationDrawer,
        LineChart,
        BarChart,
        BarChartV2,
        datetime,
        NavigationBar
    },
    data() {
        return {
            componentKey: 0,
            progress: true,
            progressChart: false,
            profile: {},
            uvax: {},
            plants: [],
            selectedPlants: [],
            selectedPlantsLabel: '',
            dataCollection: null,
            plotTypes: [
                {text: "Linea", value: "line", disabled: false},
                {text: "Barre sovrapposte", value: "bar", disabled: false},
                {text: "Barre parallele", value: "bar2", disabled: false}
            ],
            selectedPlotType: "line",
            samplingRates: [
                {text: "Quarto d'ora", value: "quarter"},
                {text: "Ora", value: "hour"},
                {text: "Giorno", value: "day"},
                {text: "Settimana", value: "week"},
                {text: "Mese", value: "month"}
            ],
            selectedSamplingRate: "hour",
            startDate: moment().subtract(2, "d").startOf("day").format("YYYY-MM-DD"),
            endDate: moment().subtract(1, "d").endOf("day").format("YYYY-MM-DD"),
            csvExportData: []
        }
    },
    watch: {
        selectedPlants() {
            this.dataCollection = null;

            if (this.profile.tipoUser == 'plant') {
                if (this.selectedPlants.length > 0) {
                    this.selectedPlantsLabel = this.selectedPlants[0].name;
                } else {
                    this.selectedPlantsLabel = "";
                }
            } else {
                // Allows to select either only UVAx or multiple other plants:
                if (this.selectedPlants.some(p => p.value === "uvaxsamplingpowerdata_inf")) {
                    this.selectedPlants.forEach((p, i) => {
                        if (p.value !== "uvaxsamplingpowerdata_inf") {
                            this.selectedPlants.splice(i, 1); // Removes all other selected elements
                        }
                    });
                    this.plants.forEach(p => {
                        if (p.value !== "uvaxsamplingpowerdata_inf") {
                            p.disabled = true;
                        }
                    });
                } else {
                    this.plants.forEach(p => p.disabled = false);
                }

                // Sets up the label with no more than two plants selected; then adds …
                if (this.selectedPlants[0]) {
                    let label = this.selectedPlants[0].name;
                    if (this.selectedPlants[1]) {
                        label += `, ${this.selectedPlants[1].name}`;
                        if (this.selectedPlants[2]) {
                            label += `, …`;
                        }
                    }

                    this.selectedPlantsLabel = label;
                } else {
                    this.selectedPlantsLabel = "";
                }
            }
        }
    },
    methods: {
        // async initComp() {
        //     await this.getPlants();
        //     // this.getSelectedPlants();
        //     // this.setResourceOptions();
        // },
        async logout() {
            await AuthService.logout();
            this.$router.push({name: "LoginPage"}).catch(() => {});
        },
        // loadData() {
        //     //Se l'user è di tipo UVAX continua a funzionare come sempre
        //     if (this.profile.tipoUser != 'plant') {
        //         if (this.selectedPlant && this.selectedPlants.length > 0) {
        //             for (let i = 0; i < this.selectedPlants.length; i++) {
        //                 let resLength = this.selectedPlants[i].length;
        //                 if (this.selectedPlants[i].substring(0, resLength - 2) == "samplingpowerdata") {
        //                     this.selectedPlants[i] = "samplingpowerdata";
        //                 }
        //             }
        //             this.getData();
        //         }
        //         // this.setResourceOptions();
        //     } else {
        //         this.selectedPlants[0] = "samplingpowerdata";
        //         this.getData();
        //     }
        // },
        // getSelectedPlants(plant) {
        //     if (plant) {
        //         this.selectedPlant = plant;
        //         this.selectedPlant_id = plant.id;
        //     } else if (this.plants.length > 0) {
        //         this.selectedPlant = this.plants[0];
        //         this.selectedPlant_id = this.plants[0].id;
        //     }
        //     // this.loadData();
        //     // this.testReliability();
        // },
        // setUVAXOptions() {
        //     if (this.profile.tipoUser != 'plant') {
        //         this.options = [];

        //         if (this.selectedPlants.includes('uvaxsamplingpowerdata_inf')) {
        //             this.options.push({text: `UVAx`, value: 'uvaxsamplingpowerdata_inf', disabled: false});
        //             for (let i = 0; i < this.plants.length; i++) {
        //                 let newOption = {text: this.plants[i].name, value: 'samplingpowerdata_' + this.plants[i].id, disabled: true};
        //                 this.options.push(newOption);
        //             }
        //         } else if (this.selectedPlants.length == 0) {
        //             this.options.push({text: `UVAx`, value: 'uvaxsamplingpowerdata_inf', disabled: false})
        //             for (let i = 0; i < this.plants.length; i++) {
        //                 let newOption = {text: this.plants[i].name, value: 'samplingpowerdata_' + this.plants[i].id, disabled: false};
        //                 this.options.push(newOption);
        //             }
        //         } else {
        //             this.options.push({text: `Potenza reale UVAx`, value: 'uvaxsamplingpowerdata_inf', disabled: true});
        //             for (let i = 0; i < this.plants.length; i++) {
        //                 let newOption = {text: this.plants[i].name, value: 'samplingpowerdata_' + this.plants[i].id, disabled: false};
        //                 this.options.push(newOption);
        //             }
        //         }
        //     }
        //     return;
        // },
        // setResourceOptions() {
        //     if (this.selectedPlant) {
        //         if (this.selectedPlants.length == 0) {
        //             if (this.profile.tipoUser != 'plant') {
        //                 this.setUVAXOptions();
        //             } /* else {
        //                 this.options = [
        //                     {text: `Impianto ${this.selectedPlant.id}`, value: 'samplingpowerdata', disabled: false}
        //                 ];
        //             } */
        //         } else if (this.profile.tipoUser != 'plant') {
        //             this.setUVAXOptions();
        //             this.selectedPlant.id = this.selectedPlants[0].split('_')[1];
        //             for (let i = 0; i < this.plants.length; i++) {
        //                 if (this.plants[i].id == this.selectedPlant.id) {
        //                     this.selectedPlant = this.plants[i];
        //                 }
        //             }
        //         } else {
        //             // this.options = [
        //             //     { text: `Impianto ${this.selectedPlant.id}`, value: 'samplingpowerdata', disabled: false }
        //             // ];
        //         }

        //         // this.testReliability();
        //     }
        // },
        // getSelectedResources() {
        //     this.selected_plot_options = [];

        //     for (let i = 0; i < this.selectedPlants.length; i++) {
        //         let resource = this.selectedPlants[i].split('_');
        //         if (resource[1] != 'inf') {
        //             this.selected_plot_options.push({plot_type: resource[0], plant_id: resource[1]});
        //         } else {
        //             this.selected_plot_options = [];
        //             for (let i = 0; i < this.plants.length; i++) {
        //                 this.selected_plot_options.push({plot_type: "samplingpowerdata", plant_id: this.plants[i].id});
        //             }
        //         }
        //     }
        // },
        async getData() {
            if (TokenService.isTokenValid()) {
                this.dataCollection = null;
                this.csvExportData = [];

                let datasets = [];
                let xAxes = [];

                let plots = [];
                if (this.profile.tipoUser === "plant") {
                    plots.push({plot_type: "samplingpowerdata", plant_id: this.selectedPlants[0].id});
                } else if (this.selectedPlants.find(p => p.id === 0)) { // If UVAX is selected, all plants are added
                    this.plants.forEach(p => plots.push({plot_type: "samplingpowerdata", plant_id: p.id, color: p.color}));
                } else {
                    this.selectedPlants.forEach(p => plots.push({plot_type: "samplingpowerdata", plant_id: p.id, color: p.color}));
                }

                if (!this.startDate && !this.endDate) return;

                this.progressChart = true;

                const params = {
                    plots: plots,
                    from_date: this.startDate,
                    to_date: this.endDate,
                    samplingRate: this.selectedSamplingRate
                };

                axios.defaults.headers.common['Authorization'] = `Bearer ${TokenService.getToken()}`;
                axios.get(`${process.env.VUE_APP_ENDPOINT_API_URI}/private/report`, {params: params}).then(res => {
                    if (res.data.length > 0) {
                        let new_plots = _.filter(plots, function(o) { return o.plant_id != 0; });
                        for (let i = 0; i < res.data.length; i++) {
                            let resourceData = res.data[i];
                            xAxes = resourceData.xAxes;

                            // Costruisco il dataset per rappresentare i grafici
                            if (resourceData.yAxes.length > 0) {
                                //let randomColour = `#${((1 << 24) * Math.random() | 0).toString(16)}`;                                
                                let plant = _.filter(this.plants, function(o) { return o.id == new_plots[i].plant_id; })
                                let plant_name = plant.length > 0 ? plant[0].name : new_plots[i].plant_id
                                let color = plant.length > 0 ? plant[0].color : "#b34ff8"
                                let label = resourceData.plot_type
                                if (resourceData.plot_type != "baseline" && resourceData.plot_type != "flexibility") {
                                    label = plant_name
                                }
                                let dataset = {
                                    label: label,
                                    data: resourceData.yAxes,
                                    borderColor: color, //randomColour,
                                    backgroundColor: color, //randomColour,
                                    fill: false
                                }
                                if (resourceData.plot_type != "baseline") {
                                    dataset.borderDash = [10, 10];
                                }
                                if (resourceData.plot_type == "flexibility") {
                                    dataset.radius = 0;
                                }
                                datasets.push(dataset);
                            }

                            // Costruisco l'array di oggetti per l'export del file CSV
                            for (let j = 0; j < resourceData.xAxes.length; j++) {
                                let rowObj = {
                                    plot_type: resourceData.plot_type,
                                    plant: new_plots[i].plant_id,
                                    time: moment(resourceData.xAxes[j]).format("YYYY-MM-DD HH:mm:ss")
                                };
                                if (resourceData.yAxes[j]) {
                                    rowObj.value = resourceData.yAxes[j];
                                }
                                this.csvExportData.push(rowObj);
                            }
                        }
                    }

                    this.dataCollection = {
                        labels: xAxes,
                        datasets: datasets
                    }

                    this.progressChart = false;
                });
            } else {
                this.logout();
            }
        },
        async getPlants() {
            if (TokenService.isTokenValid()) {
                const requestData = {
                    method: 'get',
                    headers: {
                        Authorization: `Bearer ${TokenService.getToken()}`,
                    },
                    url: `${process.env.VUE_APP_ENDPOINT_API_URI}/private/report/plant`
                };
                const res = await ApiService.customRequest(requestData);
                if (res.data) {
                    this.uvax = res.data;

                    if (this.profile.tipoUser == 'plant') {
                        this.selectedPlants = [{name: res.data.plants[0].name, value: `samplingpowerdata_${res.data.plants[0].id}`, id: res.data.plants[0].id, color: res.data.plants[0].color}];
                        this.plants.push({name: res.data.plants[0].name, value: `samplingpowerdata_${res.data.plants[0].id}`, id: res.data.plants[0].id, color: res.data.plants[0].color});
                    } else {
                        this.plants.push({name: `UVAx`, value: 'uvaxsamplingpowerdata_inf', id: 0, color: "#b34ff8"});
                        for (let i = 0; i < res.data.plants.length; i++) {
                            this.plants.push({name: res.data.plants[i].name, value: `samplingpowerdata_${res.data.plants[i].id}`, id: res.data.plants[i].id, color: res.data.plants[i].color});
                        }
                    }
                }
            } else {
                this.logout();
            }
        },
        saveImage() {
            let canvas = document.getElementById('line-chart').toDataURL('image/png');
            let link = document.createElement('a');
            link.download = 'chart';
            link.href = canvas;
            link.click();
        },
        async downloadPDF() {
            const doc = new jsPDF();
            const fontName = 'Helvetica';
            const marginX = 15;
            const marginY = 20;

            // Title:
            let startX = marginX;
            let startY = marginY;
            doc.setFont(fontName, 'bold');
            doc.setFontSize(20);
            doc.text('Dati storici', startX, startY);

            // Filters:
            startY += 5;
            doc.setFontSize(10);
            const headers = [
                [{text: 'Risorse:', bold: true}, {text: this.selectedPlants.map(p => p.name).join(", ")}],
                [{text: 'Campionamento:', bold: true}, {text: this.selectedSamplingRate}],
                [{text: 'Data iniziale:', bold: true}, {text: this.startDate}],
                [{text: 'Data finale:', bold: true}, {text: (this.endDate) ? this.endDate : '-'}]
            ];
            headers.forEach(parts => {
                startX = marginX;
                startY += 5;
                parts.forEach(part => {
                    doc.setFont(fontName, part.bold ? 'bold' : 'normal');
                    const text = part.text ?? '';
                    doc.text(text, startX, startY);
                    startX += 35;
                });
            });

            // Chart:
            startX = marginX;
            startY += 10;
            const chart = document.getElementById(['line', 'bar', 'bar2'].find(el => document.getElementById(el) !== null));
            const canvas = await html2canvas(chart, {
                scrollY: -window.scrollY,
                scale: 5 // <-- this is to increase the quality. don't raise this past 5 as it doesn't get much better and just takes longer to process
            });
            const image = canvas.toDataURL('image/jpeg', 1.0);
            const pageWidth = doc.internal.pageSize.getWidth();
            const ratio = (pageWidth - (marginX * 2)) / canvas.width;
            const canvasWidth = canvas.width * ratio;
            const canvasHeight = canvas.height * ratio;
            doc.addImage(image, 'JPEG', startX, startY, canvasWidth, canvasHeight);

            // Table:
            doc.autoTable({
                startY: startY + canvasHeight + 10,
                head: [Object.keys(this.csvExportData[0])],
                body: this.csvExportData.map(Object.values)
            });

            // Page number:
            const pageHeight = doc.internal.pageSize.getHeight();
            for (let i = 1; i <= doc.internal.getNumberOfPages(); i++) {
                doc.setPage(i);
                doc.text(`${i}`, pageWidth / 2, pageHeight - 10);
            }

            // Saving:
            const selectedResources = this.selectedPlants.map(p => p.value).join("_");
            let fileName = `historical_${selectedResources}_${this.selectedSamplingRate}_${this.startDate}`;
            fileName += this.endDate ? `_${this.endDate}` : '_now';
            doc.save(fileName);
        },
        resetZoom() {
            this.componentKey += 1;
            //this.getData();
        },
        // async testReliability() {
        //     if (TokenService.isTokenValid()) {
        //         axios.defaults.headers.common['Authorization'] = `Bearer ${TokenService.getToken()}`;
        //         axios.get(`${process.env.VUE_APP_ENDPOINT_API_URI}/private/report/reliability`, {params: {plantId: this.selectedPlant_id}}).then(res => {
        //             try {
        //                 if (res.data.length > 0) {
        //                     this.reliability = res.data[95].k_up;
        //                 } else {
        //                     this.reliability = 'Non sono presenti dati';
        //                 }
        //             } catch(e) {
        //                 console.log(e);
        //             }
        //         });
        //     } else {
        //         this.logout();
        //     }
        // }
    },
    computed: {
        loggedUser() {
            return TokenService.getPayload();
        },
    },
    async mounted() {
        this.profile = this.loggedUser.account;
        // await this.initComp();
        await this.getPlants();
        this.progress = false;
        // this.testReliability();
        // setInterval(() => {
        //     this.testReliability();
        // }, 900000);
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container{
    margin-left: 0px;
    margin-right: 0px;
}

.row {
    margin-top: 10px;
}

h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}
</style>
