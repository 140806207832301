import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
//import i18n from "./i18n";
//import "./filters";
//import {} from "./devour";
import VueBlobJsonCsv from 'vue-blob-json-csv';

Vue.use(VueBlobJsonCsv)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  //i18n,
  render: h => h(App)
}).$mount('#app')
