<script>  
  // Import the Line chart from vue-chartjs
  import { Line, mixins } from 'vue-chartjs'
  import zoom from 'chartjs-plugin-zoom'
  const { reactiveProp } = mixins
  
  export default {
    extends: Line,
    mixins: [reactiveProp],
    //props: ['options'],
    data () {
      return {        
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: true,
                labelString: "P (kW)"
              }
            }],
            xAxes: [ {
              type: "time",
              time: {
                displayFormats: {
                  hour: 'HH:mm',
                }
              },
              gridLines: {
                display: false
              },
              scaleLabel: {
                display: true,
                labelString: "Time"
              }
            }]
          },
          legend: {
            display: true
          },
          plugins: {
            zoom: {
              // Container for pan options              
              pan: {
                // Boolean to enable panning
                enabled: true,
                mode: 'x',               
                // On category scale, factor of pan velocity
                //speed: 20,
                // Minimal pan distance required before actually applying pan
                //threshold: 10,
                // Function called while the user is panning
                //onPan: function() { console.log(`I'm panning!!!`); },
                // Function called once panning is completed
                //onPanComplete: function({chart}) { console.log(`I was panned!!!`); }
              },
              // Container for zoom options
              zoom: {
                // Boolean to enable zooming
                enabled: true,
                // Enable drag-to-zoom behavior
                drag: true,
                // Drag-to-zoom effect can be customized
                mode: 'x',
                // Speed of zoom via mouse wheel
                // (percentage of zoom on a wheel event)
                speed: 0.05,
                // Minimal zoom distance required before actually applying zoom
                //threshold: 2,
                // On category scale, minimal zoom level before actually applying zoom
                //sensitivity: 3,
                // Function called while the user is zooming
                //onZoom: function() { console.log(`I'm zooming!!!`); },
                // Function called once zooming is completed
                //onZoomComplete: function() { console.log(`I was zoomed!!!`); }               
              }
            }
          },          
          responsive: true,
          maintainAspectRatio: false
        }
      }
    },
    mounted () {
      this.addPlugin(zoom);
      this.renderChart(this.chartData, this.options)
    }
  }
</script>